/* eslint-disable object-curly-newline */

import { createTheme } from '@mui/material/styles';

const colorPrimary = '#121034';
const colorIcon = '#34598C';
const borderRadius = '0px 60.48px 0px 60.48px';

const theme = createTheme({
  typography: {
    fontFamily: ['Gabarito', 'Open Sans', 'sans-serif'].join(','),
    h1: {
      fontFamily: 'Gabarito',
    },
    h2: {
      fontFamily: 'Gabarito',
    },
    h3: {
      fontFamily: 'Gabarito',
    },
    h4: {
      fontFamily: 'Gabarito',
    },
    h5: {
      fontFamily: 'Gabarito',
    },
    h6: {
      fontFamily: 'Gabarito',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: colorPrimary,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: colorPrimary,
        },
      },
    },
    MuiAvatar: { styleOverrides: { root: { backgroundColor: colorPrimary } } },
    MuiPaper: { styleOverrides: { root: { borderRadius } } },
    MuiTabs: {
      styleOverrides: {
        indicator: { backgroundColor: '#1FA26D', height: '45px', borderRadius: '15px' },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#fff',
            fontWeight: 'bold',
            zIndex: 1,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '18px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '18px',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: colorPrimary,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: { root: { color: colorIcon } },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '10px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          color: colorIcon,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: colorPrimary,
          '&:hover': {
            backgroundColor: '#2A2A5A',
          },
        },
        root: {
          borderRadius: '13px',
          fontSize: '0.875rem',
          fontWeight: 600,
        },
      },
    },
    MuiIcon: { styleOverrides: { colorPrimary: colorIcon } },
  },

  palette: {
    primary: { main: colorPrimary },
    secondary: { main: '#f2f5f9' },
    mode: 'light',
  },
});

export default theme;
