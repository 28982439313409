/* eslint-disable import/prefer-default-export, object-curly-newline */
import api from 'services';
import {
  signer,
  signerResponse,
  signerProposalBodyResponse,
  signerProposalBody,
  resendContract,
} from 'interfaces/Signer';
import { baseResponse } from 'interfaces/BaseResponse';

const path = '/signer';

export const postSignin = async ({ email, password, aplicacao: typeOfProduct }: signer): Promise<signerResponse> => {
  const response = await api.post<signerResponse>('/signin', { email, password, aplicacao: typeOfProduct });
  api.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
  return response.data;
};

export const postSignerProposal = async (payload: signerProposalBody): Promise<signerProposalBodyResponse> => {
  const response = await api.post<signerProposalBodyResponse>(`${path}`, payload);
  return response.data;
};

export const patchProposalResendContract = async (resendContractParams: resendContract): Promise<baseResponse> => {
  const response = await api.patch<baseResponse>(`${path}/resend`, resendContractParams);
  return response.data;
};

export const patchProposalResendNotification = async ({
  identifier,
}: {
  identifier: string;
}): Promise<baseResponse> => {
  const response = await api.patch<baseResponse>(`${path}/resendNotification`, { identifier });
  return response.data;
};
