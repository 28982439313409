/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
  responseBankOperation,
  responseGenre,
  responseMaritalStatus,
  responseNationality,
  responseCareers,
  responseSpecie,
  fieldsByProductList as IFieldsByProductList,
  issuingAgencyList as IIssueAgencyList,
} from 'interfaces/Parameterizations';
import { reduxTemplate } from 'interfaces/redux';

import { paramsQueryString } from 'interfaces/RouterParams';
import {
  getBankOperation,
  getGenre,
  getMaritalStatus,
  getNationality,
  getCareers,
  getSpecie,
  getFieldsByProduct,
  getIssueAgency,
} from './service';

export const bankOperation = createAsyncThunk<responseBankOperation, void, { rejectValue: reduxTemplate }>(
  'parameterizations/operacoesBancarias',
  async (_, { rejectWithValue }) => {
    try {
      const data = await getBankOperation();
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);
export const genre = createAsyncThunk<responseGenre, void, { rejectValue: reduxTemplate }>(
  'parameterizations/genre',
  async (_, { rejectWithValue }) => {
    try {
      const data = await getGenre();
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);
export const maritalStatus = createAsyncThunk<responseMaritalStatus, void, { rejectValue: reduxTemplate }>(
  'parameterizations/maritalStatus',
  async (_, { rejectWithValue }) => {
    try {
      const data = await getMaritalStatus();
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);
export const nationality = createAsyncThunk<responseNationality, void, { rejectValue: reduxTemplate }>(
  'parameterizations/nationality',
  async (_, { rejectWithValue }) => {
    try {
      const data = await getNationality();
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);
export const careers = createAsyncThunk<responseCareers, void, { rejectValue: reduxTemplate }>(
  'parameterizations/careers',
  async (_, { rejectWithValue }) => {
    try {
      const data = await getCareers();
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const specie = createAsyncThunk<responseSpecie, paramsQueryString, { rejectValue: reduxTemplate }>(
  'parameterizations/specie',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getSpecie(params);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const fieldsByProductList = createAsyncThunk<
  IFieldsByProductList,
  paramsQueryString,
  { rejectValue: reduxTemplate }
>('parameterizations/fieldsByProduct', async (params, { rejectWithValue }) => {
  try {
    const data = await getFieldsByProduct(params);
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

export const issueAgencyList = createAsyncThunk<IIssueAgencyList, paramsQueryString, { rejectValue: reduxTemplate }>(
  'parameterizations/issueAgencyList',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getIssueAgency(params);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

interface IParameterizations extends reduxTemplate {
  bankOperations: responseBankOperation;
  genreList: responseGenre;
  maritalStatusList: responseMaritalStatus;
  nationalityList: responseNationality;
  careesList: responseCareers;
  specieList: responseSpecie;
  fieldsByProductList: IFieldsByProductList;
  issueAgencyList: IIssueAgencyList;
}

const initialState: IParameterizations = {
  status: 'idle',
  message: '',
  bankOperations: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
  genreList: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
  maritalStatusList: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
  nationalityList: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
  careesList: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
  specieList: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
  fieldsByProductList: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
  issueAgencyList: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
};

export const ParametrizationReducer = createSlice({
  name: 'Parametrization',
  initialState,
  reducers: {
    clearParametrization: (state) => {
      state.status = 'idle';
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(bankOperation.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(bankOperation.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.bankOperations = action.payload;
      })
      .addCase(bankOperation.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(genre.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(genre.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.genreList = action.payload;
      })
      .addCase(genre.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(maritalStatus.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(maritalStatus.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.maritalStatusList = action.payload;
      })
      .addCase(maritalStatus.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(nationality.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(nationality.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.nationalityList = action.payload;
      })
      .addCase(nationality.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(careers.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(careers.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.careesList = action.payload;
      })
      .addCase(careers.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(specie.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(specie.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.specieList = action.payload;
      })
      .addCase(specie.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(fieldsByProductList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fieldsByProductList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.fieldsByProductList = action.payload;
      })
      .addCase(fieldsByProductList.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(issueAgencyList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(issueAgencyList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.issueAgencyList = action.payload;
      })
      .addCase(issueAgencyList.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});
export const { clearParametrization } = ParametrizationReducer.actions;
export default ParametrizationReducer.reducer;
