/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import { FileData } from 'hooks/useFileDownloader';
import { baseResponse } from 'interfaces/BaseResponse';

import {
  cellphoneValidate,
  cpfValidate,
  editFullProposal,
  editProposalResponse,
  filterValues,
  listQueryParams,
  payloadCreateProposal,
  proposal,
  proposalByIdentifierResponse,
  proposalListCsv,
  putProposalCancellationPayload,
  responseValidationCelular,
  statusHistoryProposal,
  validateCellPhoneinterface,
  validateCPFinterface,
} from 'interfaces/Proposal';
import { defaultResponse } from 'interfaces/defaultResponse';
import api from 'services';
import convertQueryParamsConditionsAndFilterValues from 'utils/object';

const path = 'proposal';

export const getProposalList = async (payload: listQueryParams, bodyParams?: filterValues): Promise<proposal> => {
  const newParams = {
    ...payload,
    ...(bodyParams
      ? convertQueryParamsConditionsAndFilterValues({ filterValues: { ...bodyParams } }, 'filterValues')
      : undefined),
  };

  const response = await api.get<proposal>('/proposal/list', { params: newParams });
  return response.data;
};

export const getProposalListCsv = async (
  payload: proposalListCsv,
  bodyParams?: Omit<filterValues, 'startDate' | 'endDate'>,
): Promise<FileData> => {
  const newParams = {
    ...payload,
    ...(bodyParams
      ? convertQueryParamsConditionsAndFilterValues({ filterValues: { ...bodyParams } }, 'filterValues')
      : undefined),
  };

  const response = await api.get<Blob>('/reports/proposals', { params: newParams, responseType: 'blob' });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, filename] = /filename="(.*)"$/.exec(response.headers['content-disposition']) ?? [];
  return { blob: response.data, fileName: filename };
};

export const getProposalByIdentifier = async (identifier: string): Promise<proposalByIdentifierResponse> => {
  const response = await api.get<proposalByIdentifierResponse>(`${path}/byIdentifier/${identifier}`);
  return response.data;
};

export const getProposalHistory = async (identifier: string): Promise<statusHistoryProposal> => {
  const response = await api.get<statusHistoryProposal>(`${path}/statusHistory/${identifier}`);
  return response.data;
};

export const getValidateCelular = async (celular: string): Promise<responseValidationCelular> => {
  const response = await api.get<responseValidationCelular>(`proposal/celularvalidate/${celular}`);
  return response.data;
};

export const postProposalIntegration = async (identifier: string): Promise<baseResponse> => {
  const response = await api.post<baseResponse>('proposal/integration', { identifier });
  return response.data;
};

export const postNewProposal = async (payload: payloadCreateProposal): Promise<proposal> => {
  const response = await api.post<proposal>('proposal/create', payload);
  return response.data;
};

export const putProposal = async (payload: editFullProposal): Promise<editProposalResponse> => {
  const response = await api.put<editProposalResponse>('proposal/fullProposal', payload);
  return response.data;
};

export const getCpfValidtoProduct = async ({ cpf, integrationCode }: validateCPFinterface): Promise<cpfValidate> => {
  const response = await api.get<cpfValidate>(`${path}/cpfValidtoProduct/${cpf}/${integrationCode}`);
  return response.data;
};

export const getCellPhoneValidate = async ({
  cellPhone,
  integrationCode,
}: validateCellPhoneinterface): Promise<cellphoneValidate> => {
  const response = await api.get<cellphoneValidate>(
    `${path}/cellPhoneValidtoProduct/${cellPhone.replace(/\D/g, '')}/${integrationCode}`,
  );
  return response.data;
};

export const putProposalCancellation = async (
  identifier: string,
  payload: putProposalCancellationPayload,
): Promise<baseResponse> => {
  const response = await api.put<baseResponse>(`proposal/cancellation/${identifier}`, payload);
  return response.data;
};

export const getCheckNumberBenefit = async (numberBenefit: string): Promise<defaultResponse> => {
  const response = await api.get<defaultResponse>(`/proposal/checkNumberBenefit/${numberBenefit}`);
  return response.data;
};
