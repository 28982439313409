import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { reduxTemplate } from 'interfaces/redux';
import { passwordRecovery, recovery } from 'interfaces/Users';

import { postRecoverPassword, postSendEmailRecovery } from './service';

export const sendEmailRecovery = createAsyncThunk('user/sendRecovery', async (body: recovery, { rejectWithValue }) => {
  try {
    const data = await postSendEmailRecovery(body);
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

export const recoveryPassword = createAsyncThunk(
  'user/passwordReset',
  async (body: passwordRecovery, { rejectWithValue }) => {
    try {
      const data = await postRecoverPassword(body);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

interface IUser extends reduxTemplate { }

const initialState: IUser = {
  status: 'idle',
  message: '',
};

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {},
});

export default user.reducer;
