/* eslint-disable object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { errorRedux, reduxTemplate } from 'interfaces/redux';
import { customerValidationDetail, responseFullCustomer, responseModelCustomer } from 'interfaces/Customer';
import { listQueryParams } from 'interfaces/Proposal';
import { getCustomerList, getFullCustomerByID, getValidCustomer } from './service';

export const customerList = createAsyncThunk<responseModelCustomer, listQueryParams, { rejectValue: errorRedux }>(
  'customer/list',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getCustomerList(params);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

export const fullCustomerDataById = createAsyncThunk<responseFullCustomer, number, { rejectValue: errorRedux }>(
  'customer/:id',
  async (id, { rejectWithValue }) => {
    try {
      const data = await getFullCustomerByID(id);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);
export const validationCustomerDetail = createAsyncThunk<customerValidationDetail, string, { rejectValue: errorRedux }>(
  'customer/validCustomer/:cpf',
  async (cpf, { rejectWithValue }) => {
    try {
      const data = await getValidCustomer(cpf);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

interface ICustomerReports extends reduxTemplate {
  customerList: responseModelCustomer;
  customerData: responseFullCustomer;
  customerValidationSales: customerValidationDetail;
}

const initialState: ICustomerReports = {
  status: 'idle',
  message: '',
  customerList: {
    status: 'success',
    message: '',
    document: [],
  },
  customerData: {
    status: 'success',
    message: '',
    document: [],
  },
  customerValidationSales: {
    status: 'success',
    message: '',
    document: [
      {
        id_cliente: 0,
        codigo_produto: 0,
      },
    ],
  },
};

export const CustomerReducer = createSlice({
  name: 'CustomerStatus',
  initialState,
  reducers: {
    clearCustomerStatus: (state) => {
      state.status = 'idle';
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(customerList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(customerList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.customerList = action.payload;
      })
      .addCase(customerList.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(fullCustomerDataById.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fullCustomerDataById.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.customerData.data = action.payload?.data;
      })
      .addCase(fullCustomerDataById.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(validationCustomerDetail.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(validationCustomerDetail.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
        state.customerValidationSales = action.payload;
      })
      .addCase(validationCustomerDetail.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      });
  },
});
export const { clearCustomerStatus } = CustomerReducer.actions;
export default CustomerReducer.reducer;
