/* eslint-disable object-curly-newline */
import api from 'services';

import {
  responseBankOperation,
  responseGenre,
  responseMaritalStatus,
  responseNationality,
  responseCareers,
  responseSpecie,
  fieldsByProductList,
  issuingAgencyList,
} from 'interfaces/Parameterizations';
import convertQueryParamsConditionsAndFilterValues from 'utils/object';
import { paramsQueryString } from 'interfaces/RouterParams';

const path = 'parameterizations';

export const getBankOperation = async (): Promise<responseBankOperation> => {
  const response = await api.get<responseBankOperation>('parameterizations/bankingOperations');
  return response.data;
};

export const getGenre = async (): Promise<responseGenre> => {
  const response = await api.get<responseGenre>('parameterizations/genre');
  return response.data;
};

export const getMaritalStatus = async (): Promise<responseMaritalStatus> => {
  const response = await api.get<responseMaritalStatus>('parameterizations/maritalStatus');
  return response.data;
};

export const getNationality = async (): Promise<responseNationality> => {
  const response = await api.get<responseNationality>('parameterizations/nationality');
  return response.data;
};

export const getCareers = async (): Promise<responseCareers> => {
  const response = await api.get<responseCareers>('parameterizations/carees');
  return response.data;
};

export const getSpecie = async (params: paramsQueryString): Promise<responseSpecie> => {
  const response = await api.get<responseSpecie>('parameterizations/species', {
    params: convertQueryParamsConditionsAndFilterValues(params),
  });
  return response.data;
};

export const getFieldsByProduct = async (params: paramsQueryString): Promise<fieldsByProductList> => {
  const response = await api.get<fieldsByProductList>(`${path}/fieldsByProduct/list`, {
    params: convertQueryParamsConditionsAndFilterValues(params),
  });

  return response.data;
};

export const getIssueAgency = async (params: paramsQueryString): Promise<issuingAgencyList> => {
  const response = await api.get<issuingAgencyList>(`${path}/issuingAgency/list`, {
    params: convertQueryParamsConditionsAndFilterValues(params),
  });

  return response.data;
};
