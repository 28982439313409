/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { baseResponse } from 'interfaces/BaseResponse';
import { reduxTemplate } from 'interfaces/redux';
import {
  resendContract,
  signer,
  signerProposalBody,
  signerProposalBodyResponse,
  signerResponse,
} from 'interfaces/Signer';
import {
  patchProposalResendContract,
  patchProposalResendNotification,
  postSignerProposal,
  postSignin,
} from './service';

export const signin = createAsyncThunk<signerResponse, signer, { rejectValue: reduxTemplate }>(
  'signer/signin',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await postSignin(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const resendContractType = createAsyncThunk<baseResponse, resendContract, { rejectValue: reduxTemplate }>(
  'signer/resend',
  async (resendContractParams, { rejectWithValue }) => {
    try {
      const data = await patchProposalResendContract(resendContractParams);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const resendNotification = createAsyncThunk<
  baseResponse,
  { identifier: string },
  { rejectValue: reduxTemplate }
>('signer/resendNotification', async ({ identifier }, { rejectWithValue }) => {
  try {
    const data = await patchProposalResendNotification({ identifier });
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

export const signerProposal = createAsyncThunk<
  signerProposalBodyResponse,
  signerProposalBody,
  { rejectValue: reduxTemplate }
>('signer/proposal', async (payload, { rejectWithValue }) => {
  try {
    const data = await postSignerProposal(payload);
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

interface ISignerReducer extends reduxTemplate {
  signed: boolean;
  signin: signerResponse;
}

const initialState: ISignerReducer = {
  status: 'idle',
  signed: false,
  message: '',
  signin: {
    status: '',
    message: '',
    user: '',
    document: [],
    token: '',
    currentRefreshToken: '',
  },
};

export const signerReducer = createSlice({
  name: 'signer',
  initialState,
  reducers: {
    clearSignin: (state) => {
      state.status = 'idle';
      state.signed = false;
      state.signin = { status: '', message: '', token: '', currentRefreshToken: '', user: '', document: [] };
    },
    setRefreshToken: (state, action: PayloadAction<{ token: string; currentRefreshToken: string }>) => {
      state.status = 'idle';
      state.signed = true;
      state.signin.token = action.payload.token;
      state.signin.currentRefreshToken = action.payload.currentRefreshToken;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signin.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(signin.fulfilled, (state, action) => {
        state.status = 'completed';
        state.signed = true;
        state.signin = action.payload;
      })
      .addCase(signin.rejected, (state) => {
        state.status = 'failed';
        state.signed = false;
      });
  },
});

export const { clearSignin, setRefreshToken } = signerReducer.actions;
export default signerReducer.reducer;
