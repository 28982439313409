import { Article, Person } from '@mui/icons-material';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export interface ISections {
  subheader: string;
  section: string;
  label?: string;
  open: boolean;
  isVisible: boolean;
  href?: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  subsections?: {
    partners: {
      title: string;
      label?: string;
      href?: string;
      icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
        muiName: string;
      };
      isVisible: boolean;
      open: boolean;
      itens: {
        title: string;
        label?: string;
        href?: string;
        icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
          muiName: string;
        };
        isVisible: boolean;
        anchorEl?: null;
        menus?: {
          title: string;
          label?: string;
          href?: string;
          isVisible: boolean;
          isNested?: boolean;
          nesteds?: {
            title: string;
            label?: string;
            href: string;
          }[];
        }[];
      }[];
    }[];
  }[];
}

const sections: ISections[] = [
  {
    subheader: 'Propostas',
    section: 'Propostas',
    label: 'Propostas',
    href: '/proposal/list',
    open: true,
    icon: Article,
    isVisible: false,
  },
  {
    subheader: 'Cliente',
    section: 'Clientes',
    label: 'Clientes',
    href: '/customer/list',
    open: true,
    icon: Person,
    isVisible: true,
  },
];

export default sections;
