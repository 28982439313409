/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { reduxTemplate } from 'interfaces/redux';
import { groupsResponse } from 'interfaces/Groups';
import { getGroupsAndPermission } from './service';

export const groupsAndPermission = createAsyncThunk<groupsResponse, string, { rejectValue: reduxTemplate }>(
  '/groups/groupsandpermission',
  async (application, { rejectWithValue }) => {
    try {
      const data = await getGroupsAndPermission(application);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

interface IGroupsAndPermissionsReducer extends reduxTemplate {
  groupsAndPermission: groupsResponse;
}

const initialState: IGroupsAndPermissionsReducer = {
  status: 'idle',
  message: '',
  groupsAndPermission: {
    message: '',
    status: '',
    document: [{ id: 0, id_grupo: 0, descricao: '' }],
    data: [
      {
        id_login: 0,
        usuario: '',
        funcionalidade: '',
        tipo: '',
        valor_interno: '',
        visualizar: false,
        editar: false,
        excluir: false,
        inserir: false,
      },
    ],
    rowsAffected: [],
  },
};

export const groupAndPermissionsReducer = createSlice({
  name: 'signer',
  initialState,
  reducers: {
    clearGroupAndPermissions: (state) => {
      state.status = 'idle';
      state.groupsAndPermission = { status: '', message: '', data: [], document: [], rowsAffected: [] };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(groupsAndPermission.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(groupsAndPermission.fulfilled, (state, action) => {
        state.status = 'completed';
        state.groupsAndPermission = action.payload;
      })
      .addCase(groupsAndPermission.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      });
  },
});

export const { clearGroupAndPermissions } = groupAndPermissionsReducer.actions;
export default groupAndPermissionsReducer.reducer;
