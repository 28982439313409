/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
  modelRelacaoProdutoBeneficio,
  productListInterface,
  productsPayload,
  responsePaymentByProduct,
} from 'interfaces/Product';
import { reduxTemplate } from 'interfaces/redux';

import { paramsQueryString } from 'interfaces/RouterParams';
import {
  getDescriptionProduct,
  getPaymentByProduct,
  getProductByBenefitsList,
  getProductList,
  getProductsForCustomer,
} from './service';

export const getPaymentByProductId = createAsyncThunk<
  responsePaymentByProduct,
  productsPayload,
  { rejectValue: reduxTemplate }
>('product/payment', async (payload: productsPayload, { rejectWithValue }) => {
  try {
    const data: any = await getPaymentByProduct(payload);
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

export const getProductByBenefits = createAsyncThunk<
  modelRelacaoProdutoBeneficio,
  number,
  { rejectValue: reduxTemplate }
>('product/productByBenefitsList', async (productId: number, { rejectWithValue }) => {
  try {
    const data: any = await getProductByBenefitsList(productId);
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

export const getProduct = createAsyncThunk<productListInterface, productsPayload, { rejectValue: reduxTemplate }>(
  'product/',
  async (payload: productsPayload, { rejectWithValue }) => {
    try {
      const data = await getDescriptionProduct(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const productListForCustomer = createAsyncThunk<
  productListInterface,
  { customer: string },
  { rejectValue: reduxTemplate }
>('product/forCustomer', async (payload, { rejectWithValue }) => {
  try {
    const data = await getProductsForCustomer(payload);
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

export const productList = createAsyncThunk<productListInterface, paramsQueryString, { rejectValue: reduxTemplate }>(
  'product/list',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await getProductList(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

interface IProducts extends reduxTemplate {
  paymentByProduct: responsePaymentByProduct;
  productsList: productListInterface;
  productDescription: productListInterface;
  productByBenefits: modelRelacaoProdutoBeneficio;
  productsForCustomer: productListInterface;
}

const initialState: IProducts = {
  status: 'idle',
  message: '',
  paymentByProduct: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
    data: {
      codigoProduto: -1,
      bank: [],
      nome: '',
    },
  },
  productByBenefits: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
  productsForCustomer: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
  productsList: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
  productDescription: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
};

export const ProductStatusReducer = createSlice({
  name: 'ProductStatus',
  initialState,
  reducers: {
    clearProductStatus: (state) => {
      state.status = 'idle';
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentByProductId.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getPaymentByProductId.fulfilled, (state, action) => {
        state.status = 'completed';
        state.paymentByProduct = action.payload;
        state.message = action.payload.message;
      })
      .addCase(getPaymentByProductId.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(getProduct.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.status = 'completed';
        state.productDescription = action.payload;
        state.message = action.payload.message;
      })
      .addCase(getProduct.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(productList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(productList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.productsList = action.payload;
        state.message = action.payload.message;
      })
      .addCase(productList.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(productListForCustomer.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(productListForCustomer.fulfilled, (state, action) => {
        state.status = 'completed';
        state.productsForCustomer = action.payload;
        state.message = action.payload.message;
      })
      .addCase(productListForCustomer.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(getProductByBenefits.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getProductByBenefits.fulfilled, (state, action) => {
        state.status = 'completed';
        state.productByBenefits = action.payload;
        state.message = action.payload.message;
      })
      .addCase(getProductByBenefits.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      });
  },
});
export const { clearProductStatus } = ProductStatusReducer.actions;
export default ProductStatusReducer.reducer;
