/* eslint-disable import/prefer-default-export */
import {
  modelRelacaoProdutoBeneficio,
  productListInterface,
  productsPayload,
  responsePaymentByProduct,
} from 'interfaces/Product';
import { paramsQueryString } from 'interfaces/RouterParams';
import api from 'services';
import convertQueryParamsConditionsAndFilterValues from 'utils/object';

const path = '/product';

export const getPaymentByProduct = async (payload: productsPayload): Promise<responsePaymentByProduct> => {
  const response = await api.get<responsePaymentByProduct>(`${path}/payment/${payload.productsId}`);
  return response.data;
};

export const getProductByBenefitsList = async (productId?: number): Promise<modelRelacaoProdutoBeneficio> => {
  // eslint-disable-next-line object-curly-newline
  const response = await api.get<modelRelacaoProdutoBeneficio>(`${path}/productByBenefitsList`, {
    params: { productId },
    // eslint-disable-next-line object-curly-newline
  });
  return response.data;
};

export const getDescriptionProduct = async (payload: productsPayload): Promise<productListInterface> => {
  const response = await api.get<productListInterface>(`${path}/${payload.productsId}`);
  return response.data;
};

export const getProductsForCustomer = async (data: { customer: string }): Promise<productListInterface> => {
  const { customer } = data;
  const response = await api.get<productListInterface>(`${path}/forCustomer/${customer}`);
  return response.data;
};

export const getProductList = async (params: paramsQueryString): Promise<productListInterface> => {
  const response = await api.get<productListInterface>(`${path}/list`, {
    params: convertQueryParamsConditionsAndFilterValues(params),
  });
  return response.data;
};
