/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { baseResponse } from 'interfaces/BaseResponse';
import { payloadSelfValidation, responseFiles } from 'interfaces/Files';
import { reduxTemplate } from 'interfaces/redux';
import { paramsQueryString } from 'interfaces/RouterParams';
import { getFiles, patchSelfValidation, postFiles } from './service';

export const allSendFiles = createAsyncThunk<baseResponse, FormData, { rejectValue: reduxTemplate }>(
  'files/',
  async (searchAdvanced, { rejectWithValue }) => {
    try {
      const data: any = await postFiles(searchAdvanced);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

export const updateSelfValidation = createAsyncThunk<
  baseResponse,
  payloadSelfValidation,
  { rejectValue: reduxTemplate }
>('files/selfValidation', async (payload, { rejectWithValue }) => {
  try {
    const data: any = await patchSelfValidation(payload);
    return data;
  } catch (error) {
    const err = error as AxiosError<reduxTemplate>;
    return rejectWithValue(err.response?.data as reduxTemplate);
  }
});

export const listFiles = createAsyncThunk<responseFiles, paramsQueryString, { rejectValue: reduxTemplate }>(
  'files/listFiles',
  async (params, { rejectWithValue }) => {
    try {
      const data = await getFiles(params);
      return data;
    } catch (error) {
      const err = error as AxiosError<reduxTemplate>;
      return rejectWithValue(err.response?.data as reduxTemplate);
    }
  },
);

interface IFiles extends reduxTemplate {
  sendFiles: baseResponse;
  selfValidarion: baseResponse;
}

const initialState: IFiles = {
  status: 'idle',
  message: '',
  sendFiles: {
    document: [],
    status: '',
    message: '',
    rowsAffected: [],
  },
  selfValidarion: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
};

export const FilesStatusReducer = createSlice({
  name: 'FilesStatus',
  initialState,
  reducers: {
    clearFilesStatus: (state) => {
      state.status = 'idle';
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allSendFiles.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(allSendFiles.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
      })
      .addCase(allSendFiles.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      })
      .addCase(updateSelfValidation.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(updateSelfValidation.fulfilled, (state, action) => {
        state.status = 'completed';
        state.message = action.payload.message;
      })
      .addCase(updateSelfValidation.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      });
  },
});
export const { clearFilesStatus } = FilesStatusReducer.actions;
export default FilesStatusReducer.reducer;
