import { paramsQueryString } from 'interfaces/RouterParams';
import { FieldPath } from 'react-hook-form';

const convertQueryParamsConditionsAndFilterValues = (
  values: paramsQueryString,
  groupBy: string = 'conditions',
): { [key: string]: string } => {
  const queryParams: { [key: string]: string } = {};
  for (const key in values.values) {
    if (Object.prototype.hasOwnProperty.call(values.values, key)) {
      queryParams[`values[${key}]`] = `${values.values[key]}`;
    }
  }
  for (const key in values.conditions) {
    if (Object.prototype.hasOwnProperty.call(values.conditions, key)) {
      queryParams[`${groupBy}[${key}]`] = `${values.conditions[key]}`;
    }
  }

  for (const key in values.filterValues) {
    if (values.filterValues[key] !== null) {
      queryParams[`${groupBy}[${key}]`] = `${values.filterValues[key]}`;
    }
  }

  if (values.fields) {
    queryParams.fields = values.fields.join(',');
  }

  return queryParams;
};

export const getPaths = <T extends object>(obj: T, path: string[] = [], paths: string[] = []): FieldPath<T>[] => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const element = obj[key];
      const newPath = path.concat(key);
      paths.push(newPath.join('.'));
      if (typeof element === 'object' && element !== null && !(Array.isArray(element))) {
        getPaths(element, newPath, paths);
      }
    }
  }
  return paths as FieldPath<T>[];
};

export default convertQueryParamsConditionsAndFilterValues;
