/* eslint-disable react/react-in-jsx-scope */
import { forwardRef, useCallback } from 'react';
import { useSnackbar, SnackbarContent, CustomContentProps } from 'notistack';
import { Alert, AlertColor } from '@mui/material';

interface ReportCompleteProps extends CustomContentProps {
  severity: AlertColor;
}

const CustomAlert = forwardRef<HTMLDivElement, ReportCompleteProps>(({ id, ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const { message, severity } = props;

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref} style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Alert variant="filled" severity={severity} onClose={handleDismiss}>
        {message}
      </Alert>
    </SnackbarContent>
  );
});

CustomAlert.displayName = 'CustomAlert';

export default CustomAlert;
