import { passwordRecovery, recovery } from 'interfaces/Users';

import api from 'services';

export const postSendEmailRecovery = async (payload: recovery) => {
  const response = await api.post('/sendRecovery', payload);
  return response.data;
};

export const postRecoverPassword = async (payload: passwordRecovery) => {
  const response = await api.put('/passwordReset', payload);
  return response.data;
};
