import React, { Component, ErrorInfo, ReactNode } from 'react';
import ErrorFallback from 'components/ErrorFallback';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error;
}

class ErrorBoundary extends Component<Props, State> {
  // eslint-disable-next-line react/state-in-constructor
  public state: State = {
    hasError: false,
    error: new Error(),
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo);
  }

  public resetBoundary() {
    this.setState({
      hasError: false,
      error: new Error(),
    });
  }

  public render() {
    const { hasError, error } = this.state;
    const { children } = this.props;
    if (hasError) {
      // eslint-disable-next-line react/jsx-no-bind
      return <ErrorFallback error={error} resetBoundary={this.resetBoundary.bind(this)} />;
    }

    return children;
  }
}

export default ErrorBoundary;
