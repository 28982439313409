/* eslint-disable import/prefer-default-export */
import convertQueryParamsConditionsAndFilterValues from 'utils/object';

import api from 'services';
import { reasonListResponse } from 'interfaces/Reason';
import { paramsQueryString } from 'interfaces/RouterParams';

export const getReasonList = async (params: paramsQueryString): Promise<reasonListResponse> => {
  const response = await api.get<reasonListResponse>(
    '/reason',
    { params: convertQueryParamsConditionsAndFilterValues(params) },
  );
  return response.data;
};
