/* eslint-disable import/prefer-default-export */
import api from 'services';
import { signaturesStatus, clientReportsResponse, clientReportsId, FilterDate } from 'interfaces/SignaturesStatus';

// export const getSignaturesStatus = async (): Promise<signaturesStatus> => {
//   const response = await api.get<signaturesStatus>('/proposal/list');
//   return response.data;
// };
export const getSignaturesStatus = async (query: FilterDate): Promise<signaturesStatus> => {
  const response = await api.get<signaturesStatus>(
    `/proposal/entities/list?startDate=${query.startDate || "''"}&endDate=${query.endDate || "''"}`,
  );
  return response.data;
};
export const clientReports = async (query: clientReportsId): Promise<clientReportsResponse> => {
  const response = await api.get<clientReportsResponse>(
    `/proposal/entities/proposalpendings?id=${query.id}&startDate=${query.startDate || "''"}&endDate=${
      query.endDate || "''"
    }`,
  );
  return response.data;
};

// export const clientReports = async (payload: clientReportsId): Promise<clientReportsResponse> => {
//   const response = await api.get<clientReportsResponse>('/reports/client/', { params: payload });
//   return response.data;
// };
