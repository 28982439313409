import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import extractTokenData from 'utils/extractTokenData';
import { clearSignin } from 'store/ducks/Signer';
import { useAppDispatch, useAppSelector } from './useAppStore';

const useSession = () => {
  const signinData = useAppSelector((state) => {
    return state.signer;
  });
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    signed,
    signin: { currentRefreshToken },
  } = signinData;

  const tokenData = currentRefreshToken ? extractTokenData(currentRefreshToken) : null;

  const logoffIfExpired = useCallback(() => {
    const expired = !tokenData || Date.now() / 1000 > tokenData.payload.exp;
    if (!signed || !expired) {
      return;
    }
    dispatch(clearSignin());
    enqueueSnackbar('Tempo da sessão expirou, logue novamente!', {
      persist: true,
      variant: 'customAlert',
      severity: 'info',
    });
  }, [tokenData, dispatch, enqueueSnackbar, signed]);

  return { logoffIfExpired };
};

export default useSession;
