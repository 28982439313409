/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense, lazy } from 'react';
import SuspenseLoader from 'components/SuspenseLoader';

const Loader = (Component: any) => {
  return (props: any) => {
    return (
      <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
      </Suspense>
    );
  };
};

const Maintenance = Loader(
  lazy(() => {
    return import('pages/Maintenance');
  }),
);

const FormProposalStatus = Loader(
  lazy(() => {
    return import('pages/Proposal/FormView');
  }),
);

const Layout = Loader(
  lazy(() => {
    return import('components/Layout');
  }),
);

const ListView = Loader(
  lazy(() => {
    return import('pages/Proposal/ListView');
  }),
);

const NewProposal = Loader(
  lazy(() => {
    return import('pages/Proposal/NewProposal');
  }),
);

const Home = Loader(
  lazy(() => {
    return import('pages/Home');
  }),
);

const Signin = Loader(
  lazy(() => {
    return import('pages/Signin');
  }),
);

const Recovery = Loader(
  lazy(() => {
    return import('pages/Recovery');
  }),
);

const CustomerList = Loader(
  lazy(() => {
    return import('pages/Customer/ListView');
  }),
);

const FormCustomerStatus = Loader(
  lazy(() => {
    return import('pages/Customer/FormView');
  }),
);

const ProductSelection = Loader(
  lazy(() => {
    return import('pages/Proposal/ProductSelection');
  }),
);

interface IRoutes {
  path: string;
  title: string;
  component: JSX.Element;
  isPrivate: boolean;
}

const routes: IRoutes[] = [
  {
    path: '/',
    title: 'Home',
    component: <Home />,
    isPrivate: true,
  },
  {
    path: '/maintenance',
    title: 'Maintenance',
    component: <Maintenance />,
    isPrivate: false,
  },
  {
    path: '/signin',
    title: 'Signin',
    component: <Signin />,
    isPrivate: false,
  },
  {
    path: '/recoverypassword/:base64',
    title: 'Recovery',
    component: <Recovery />,
    isPrivate: false,
  },
  {
    path: '/*',
    title: 'Layout',
    component: <Layout />,
    isPrivate: false,
  },
  {
    path: '/proposal/list',
    title: 'Proposal List',
    component: <ListView />,
    isPrivate: true,
  },
  {
    path: '/proposal/:id',
    title: 'Proposal By Id',
    component: <FormProposalStatus />,
    isPrivate: true,
  },
  {
    path: '/proposal/new/:productid',
    title: 'dashboard',
    component: <NewProposal />,
    isPrivate: true,
  },
  {
    path: '/proposal/productselection',
    title: 'New Proposal',
    component: <ProductSelection />,
    isPrivate: true,
  },
  {
    path: '/customer/list',
    title: 'Clients',
    component: <CustomerList />,
    isPrivate: true,
  },
  {
    path: '/customer/:id',
    title: 'Cliente',
    component: <FormCustomerStatus />,
    isPrivate: true,
  },
];

export default routes;
