/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = { closeDrawer: false };

export const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setCloseDrawer: (state, action: PayloadAction<{ closeDrawer: boolean }>) => {
      state.closeDrawer = action.payload.closeDrawer;
    },
  },
});

export default settings.reducer;
export const { setCloseDrawer } = settings.actions;
